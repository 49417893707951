.root {
  --markdown--margin: var(--g--card-gap);
  --markdown--list-counter-width: calc(var(--markdown--margin) * 1.5);

  user-select: text;

  font-size: 15px;
  line-height: 1.5;
  color: var(--g--color-light-grey);
  word-break: break-word;

  & p {
    margin: 0 0 var(--markdown--margin) 0;
  }

  & hr {
    margin: calc(var(--markdown--margin) * 2) 0;
    border: 0;
    border-top: 1px solid var(--g--glass-light-100);
  }

  & blockquote {
    margin: 0 0 var(--markdown--margin) 0;
    padding: 0 0 0 var(--markdown--margin);
    border-left: 1px solid var(--g--glass-light-100);
  }

  & ul,
  & ol {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: calc(var(--markdown--margin) / 3) calc(var(--markdown--margin) / 2);

    margin: 0 0 var(--markdown--margin) 0;
    padding: 0;

    list-style-type: none;

    &:is(ol) {
      counter-reset: item;
    }

    li & {
      margin: 0;
    }

    .liContent > & {
      margin-top: calc(var(--markdown--margin) / 3);
    }
  }

  & li {
    display: contents;

    & .liMarker {
      display: flex;
      align-items: flex-start;

      font-family: var(--g--font-mono);
      color: var(--g--color-ray-orange);
      white-space: nowrap;

      ol > li > & {
        &::before {
          content: counters(item, '.') ' ';
          counter-increment: item;
        }

        & > svg {
          display: none;
        }
      }

      ul > li > & > svg {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-top: calc((15px * 1.5) / 2 - 4px);
      }
    }

    & .liContent {
      display: block;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: calc(var(--markdown--margin) * 1.5) 0 var(--markdown--margin) 0;

    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--g--color-white);
  }

  & a {
    color: var(--g--color-ray-orange);
    text-decoration: none;

    html[data-nav-device='mouse'] &:hover,
    html[data-nav-device='keyboard'] &:focus {
      text-decoration: underline;
    }

    html[data-nav-device='keyboard'] &:not(:disabled):focus {
      border-radius: 4px;
      outline: 1px solid var(--g--color-white);
      outline-offset: 2px;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
