.root {
  /* These are set with JS */
  --shimmer--strength: 0%;
  --shimmer--duration: 0ms;
  --shimmer--iteration-count: 1;

  position: absolute;
  inset: 0;

  overflow: hidden;

  opacity: 0;

  transition: opacity 200ms;

  &.isActive {
    opacity: 1;
  }
}

.wrapper {
  position: absolute;
  inset: 0;
  transform: translate(-80%);

  width: 200%;

  opacity: 0;

  .isActive & {
    animation-name: glare;
    animation-duration: var(--shimmer--duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: var(--shimmer--iteration-count);
  }
}

.ripple {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    -60deg,
    rgb(255 255 255 / 0%) 40%,
    rgb(255 255 255 / var(--shimmer--strength)) 45%,
    rgb(255 255 255 / var(--shimmer--strength)) 55%,
    rgb(255 255 255 / 0%) 60%
  );
}

@keyframes glare {
  0% {
    transform: translate(-80%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(50%);
    opacity: 0;
  }
}
