.root {
  pointer-events: none;
  contain-intrinsic-size: 100% 100%;

  position: fixed;
  z-index: 100000;
  inset: 0;

  contain: strict;
  overflow: hidden;

  width: 100%;
  height: 100%;

  &.isOpen {
    pointer-events: auto;
  }
}

.overlay {
  position: absolute;
  inset: 0;

  opacity: 0;
  background: rgb(0 0 0 / 80%);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .root.isOpen & {
    opacity: 1;
  }
}

.content {
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
}
