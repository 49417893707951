.root {
  position: fixed;
  z-index: 100;
  top: 16px;
  right: 0;

  display: flex;

  width: 100%;
  max-width: 616px;
  padding: 0 calc(16px + 14px) 0 16px;
}

.content {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 616px;
  padding: 12px 20px;

  background: #0009;
  border: 1px solid #fffb;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 #00000059;

  .isVisible & {
    animation: slideIn 500ms cubic-bezier(0.575, 0.01, 0.01, 1) forwards;
  }

  .isHidden & {
    animation: slideOut 500ms cubic-bezier(0.575, 0.01, 0.01, 1) forwards;
  }

  @media (width >= 600px) {
    & {
      flex-wrap: nowrap;
    }
  }
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 16px;
  align-items: flex-start;

  @media (width >= 600px) {
    & {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.description {
  margin: 0;
  font-size: 14px;

  @media (width >= 600px) {
    & {
      flex-grow: 2;
      min-width: auto;
      text-wrap: nowrap;
    }
  }
}

.closeButton {
  &:has(svg) {
    width: 18px;
    height: 18px;
    background: none;
  }
}

& .closeIcon {
  cursor: pointer;
  color: #fff;
  opacity: 0.4;
  transition: opacity 0.15 ease-out;

  html[data-nav-device='mouse'] .closeButton:hover &,
  html[data-nav-device='keyboard'] .closeButton:focus & {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(40px);
    opacity: 0;
    backdrop-filter: blur(0);
  }

  to {
    transform: translateY(0);
    opacity: 1;
    backdrop-filter: blur(20px);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
    backdrop-filter: blur(20px);
  }

  to {
    transform: translateY(40px);
    opacity: 0;
    backdrop-filter: blur(0);
  }
}
