/* Global variables */

:root {
  /* Set default focus scroll padding */
  --focus-scroll-padding: 24px;

  /**
   * Compute root scrollbar width/height and "true" 100vw/100vh sizes. Note that
   * the root-scrollbar-width/height are computed dynamically in
   * useRootScrollbarSizes hook.
   */
  --g--root-scrollbar-width: 0px;
  --g--root-scrollbar-height: 0px;
  --g--100vw: calc(100vw - var(--g--root-scrollbar-width));
  --g--100vh: calc(100vh - var(--g--root-scrollbar-height));

  /* Fonts (the specific font variables are defined in _app.tsx) */
  --g--font-fallback: arial, helvetica, sans-serif;
  --g--font: var(--g--font-suisse-intl, var(--g--font-fallback));
  --g--font-condensed: var(--g--font-suisse-intl-condensed, var(--g--font-fallback));
  --g--font-mono: var(--g--font-suisse-intl-mono, var(--g--font-fallback));

  /* Basic colors */
  --g--color-ray-orange: #ff6548;
  --g--color-white: #fff;
  --g--color-sky: #489cff;
  --g--color-light-sky: #d6f1f1;
  --g--color-jet-black: #000;
  --g--color-black: #150011;
  --g--color-charcoal: #222;
  --g--color-medium-grey: #4b5557;
  --g--color-grey: #bac7c6;
  --g--color-light-grey: #d7e1e0;
  --g--color-live-red: #f33;
  --g--color-ocean-blue: #32b9b9;

  /* Glass colors */
  --g--glass-light-100: rgb(255 255 255 / 32%);
  --g--glass-light-500: rgb(255 255 255 / 48%);
  --g--glass-light-900: rgb(255 255 255 / 64%);
  --g--glass-dark-100: rgb(0 0 0 / 30%);
  --g--glass-dark-500: rgb(0 0 0 / 40%);
  --g--glass-dark-900: rgb(0 0 0 / 50%);

  /* Margins */
  --g--margin-xs: 6px;
  --g--margin-sm: 12px;
  --g--margin-md: 24px;
  --g--margin-lg: 36px;
  --g--margin-xl: 48px;
  --g--margin-viewport: 52px;

  /* Border radii */
  --g--border-radius-sm: 6px;
  --g--border-radius-md: 12px;

  /* Buttons */
  --g--button-border-radius: 6px;
  --g--button-anim-duration: 0.15s;
  --g--button-anim-easing: ease-out;

  /* Shadows & highlights */
  --g--shadow-sm: 0px 2px 8px 0px rgb(0 0 0 / 35%);
  --g--shadow-md: 0px 4px 16px 0px rgb(0 0 0 / 35%);
  --g--shadow-xl: 0px 8px 24px 0px rgb(0 0 0 / 70%);
  --g--highlight-sm: 0px -1px 0px 0px rgb(255 255 255 / 20%);
  --g--highlight-md: 0px -1px 0px 0px rgb(255 255 255 / 40%);
  --g--inset-highlight-sm: inset 0px 1px 0px 0px rgb(255 255 255 / 20%);
  --g--inset-highlight-md: inset 0px 1px 0px 0px rgb(255 255 255 / 40%);

  /* Animations */
  --g--anim-duration: 0.2s;
  --g--anim-easing: ease-out;
  --g--anim-bounce-easing: cubic-bezier(0.33, 0.975, 0, 1.65);

  /* Cards */
  --g--card-gap: 16px;
  --g--card-action-gap: calc(var(--g--card-gap) / 2);
  --g--card-action-height: 34px;
  --g--card-border-radius: 8px;

  /* Cards - Landscape - Normal */
  --g--card-landscape-medium-aspect-ratio: calc(16 / 9);
  --g--card-landscape-medium-count: 1;
  --g--card-landscape-medium-width-unitless: 0; /* Computed with JS */
  --g--card-landscape-medium-height-unitless: 0; /* Computed with JS */
  --g--card-landscape-medium-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-landscape-medium-count) - 1))
      ) /
      var(--g--card-landscape-medium-count)
  );
  --g--card-landscape-medium-height: calc(
    var(--g--card-landscape-medium-width) / var(--g--card-landscape-medium-aspect-ratio)
  );

  /* Cards - Landscape - Big */
  --g--card-landscape-large-aspect-ratio: calc(16 / 9);
  --g--card-landscape-large-count: 1;
  --g--card-landscape-large-width-unitless: 0; /* Computed with JS */
  --g--card-landscape-large-height-unitless: 0; /* Computed with JS */
  --g--card-landscape-large-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-landscape-large-count) - 1))
      ) /
      var(--g--card-landscape-large-count)
  );
  --g--card-landscape-large-height: calc(
    var(--g--card-landscape-large-width) / var(--g--card-landscape-large-aspect-ratio)
  );

  /* Cards - Portrait - Normal */
  --g--card-portrait-medium-aspect-ratio: calc(21 / 25);
  --g--card-portrait-medium-count: 1;
  --g--card-portrait-medium-width-unitless: 0; /* Computed with JS */
  --g--card-portrait-medium-height-unitless: 0; /* Computed with JS */
  --g--card-portrait-medium-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-portrait-medium-count) - 1))
      ) /
      var(--g--card-portrait-medium-count)
  );
  --g--card-portrait-medium-height: calc(
    var(--g--card-portrait-medium-width) / var(--g--card-portrait-medium-aspect-ratio)
  );

  /* Cards - Portrait - Big */
  --g--card-portrait-large-aspect-ratio: calc(21 / 25);
  --g--card-portrait-large-count: 1;
  --g--card-portrait-large-width-unitless: 0; /* Computed with JS */
  --g--card-portrait-large-height-unitless: 0; /* Computed with JS */
  --g--card-portrait-large-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-portrait-large-count) - 1))
      ) /
      var(--g--card-portrait-large-count)
  );
  --g--card-portrait-large-height: calc(
    var(--g--card-portrait-large-width) / var(--g--card-portrait-large-aspect-ratio)
  );

  /* Cards - Sizes - Normal */

  @media (width >= 620px) {
    & {
      --g--card-landscape-medium-count: 2;
      --g--card-portrait-medium-count: 2;
    }
  }

  @media (width >= 864px) {
    & {
      --g--card-landscape-medium-count: 3;
      --g--card-portrait-medium-count: 3;
    }
  }

  @media (width >= 1108px) {
    & {
      --g--card-landscape-medium-count: 4;
      --g--card-portrait-medium-count: 4;
    }
  }

  @media (width >= 1352px) {
    & {
      --g--card-landscape-medium-count: 5;
      --g--card-portrait-medium-count: 5;
    }
  }

  @media (width >= 1596px) {
    & {
      --g--card-landscape-medium-count: 6;
      --g--card-portrait-medium-count: 6;
    }
  }

  @media (width >= 1840px) {
    & {
      --g--card-landscape-medium-count: 7;
      --g--card-portrait-medium-count: 7;
    }
  }

  /* The 8 and 9 card setups are aimed for ultrawide screens and beyond. */
  @media (width >= 2084px) and (aspect-ratio >= 2) {
    & {
      --g--card-landscape-medium-count: 8;
      --g--card-portrait-medium-count: 8;
    }
  }

  @media (width >= 2328px) and (aspect-ratio >= 3) {
    & {
      --g--card-landscape-medium-count: 9;
      --g--card-portrait-medium-count: 9;
    }
  }

  /* Cards - Landscape - Big - Sizes */

  @media (width >= 620px) {
    & {
      --g--card-landscape-large-count: 2;
      --g--card-portrait-large-count: 2;
    }
  }

  @media (width >= 864px) {
    & {
      --g--card-landscape-large-count: 3;
      --g--card-portrait-large-count: 3;
    }
  }

  @media (width >= 1108px) {
    & {
      --g--card-landscape-large-count: 3;
      --g--card-portrait-large-count: 3;
    }
  }

  @media (width >= 1352px) {
    & {
      --g--card-landscape-large-count: 4;
      --g--card-portrait-large-count: 4;
    }
  }

  @media (width >= 1596px) {
    & {
      --g--card-landscape-large-count: 5;
      --g--card-portrait-large-count: 5;
    }
  }

  @media (width >= 1840px) {
    & {
      --g--card-landscape-large-count: 6;
      --g--card-portrait-large-count: 6;
    }
  }

  /* The 7 and 8 card setups are aimed for ultrawide screens and beyond. */
  @media (width >= 2084px) and (aspect-ratio >= 2) {
    & {
      --g--card-landscape-large-count: 7;
      --g--card-portrait-large-count: 7;
    }
  }

  @media (width >= 2328px) and (aspect-ratio >= 3) {
    & {
      --g--card-landscape-large-count: 8;
      --g--card-portrait-large-count: 8;
    }
  }
}

/* Style overrides for all elements */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Root element styles */

html {
  user-select: none;

  overflow: hidden;
  overscroll-behavior: none;
  display: none;

  height: 100%;

  font-size: 62.5%;
  color: var(--g--color-white);

  background-color: var(--g--color-jet-black);

  &.isRay {
    display: block;
  }
}

body {
  position: relative;

  overflow: hidden;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;
  margin: 0;

  font-family: var(--g--font);
  font-size: 18px;
  line-height: 1.5;
}

input,
textarea {
  font-family: var(--g--font);
  background-color: transparent;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  scroll-behavior: smooth;

  position: relative;

  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: var(--g--color-black);
    box-shadow: inset 1px 0 4px var(--g--glass-dark-100);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--g--glass-light-100);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--g--glass-light-900);
  }
}
