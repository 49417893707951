/* Global variables */

:root {
  /* Set default focus scroll padding */
  --focus-scroll-padding: 24px;

  /**
   * Compute root scrollbar width/height and "true" 100vw/100vh sizes. Note that
   * the root-scrollbar-width/height are computed dynamically in
   * useRootScrollbarSizes hook.
   */
  --g--root-scrollbar-width: 0px;
  --g--root-scrollbar-height: 0px;
  --g--100vw: calc(100vw - var(--g--root-scrollbar-width));
  --g--100vh: calc(100vh - var(--g--root-scrollbar-height));

  /* Fonts (the specific font variables are defined in _app.tsx) */
  --g--font-fallback: arial, helvetica, sans-serif;
  --g--font: var(--g--font-suisse-intl, var(--g--font-fallback));
  --g--font-condensed: var(--g--font-suisse-intl-condensed, var(--g--font-fallback));
  --g--font-mono: var(--g--font-suisse-intl-mono, var(--g--font-fallback));

  /* Basic colors */
  --g--color-ray-orange: #ff6548;
  --g--color-white: #fff;
  --g--color-sky: #489cff;
  --g--color-light-sky: #d6f1f1;
  --g--color-jet-black: #000;
  --g--color-black: #150011;
  --g--color-charcoal: #222;
  --g--color-medium-grey: #4b5557;
  --g--color-grey: #bac7c6;
  --g--color-light-grey: #d7e1e0;
  --g--color-live-red: #f33;
  --g--color-ocean-blue: #32b9b9;

  /* Glass colors */
  --g--glass-light-100: rgb(255 255 255 / 32%);
  --g--glass-light-500: rgb(255 255 255 / 48%);
  --g--glass-light-900: rgb(255 255 255 / 64%);
  --g--glass-dark-100: rgb(0 0 0 / 30%);
  --g--glass-dark-500: rgb(0 0 0 / 40%);
  --g--glass-dark-900: rgb(0 0 0 / 50%);

  /* Margins */
  --g--margin-xs: 6px;
  --g--margin-sm: 12px;
  --g--margin-md: 24px;
  --g--margin-lg: 36px;
  --g--margin-xl: 48px;
  --g--margin-viewport: 52px;

  /* Border radii */
  --g--border-radius-sm: 6px;
  --g--border-radius-md: 12px;

  /* Buttons */
  --g--button-border-radius: 6px;
  --g--button-anim-duration: 0.15s;
  --g--button-anim-easing: ease-out;

  /* Shadows & highlights */
  --g--shadow-sm: 0px 2px 8px 0px rgb(0 0 0 / 35%);
  --g--shadow-md: 0px 4px 16px 0px rgb(0 0 0 / 35%);
  --g--shadow-xl: 0px 8px 24px 0px rgb(0 0 0 / 70%);
  --g--highlight-sm: 0px -1px 0px 0px rgb(255 255 255 / 20%);
  --g--highlight-md: 0px -1px 0px 0px rgb(255 255 255 / 40%);
  --g--inset-highlight-sm: inset 0px 1px 0px 0px rgb(255 255 255 / 20%);
  --g--inset-highlight-md: inset 0px 1px 0px 0px rgb(255 255 255 / 40%);

  /* Animations */
  --g--anim-duration: 0.2s;
  --g--anim-easing: ease-out;
  --g--anim-bounce-easing: cubic-bezier(0.33, 0.975, 0, 1.65);

  /* Cards */
  --g--card-gap: 16px;
  --g--card-action-gap: calc(var(--g--card-gap) / 2);
  --g--card-action-height: 34px;
  --g--card-border-radius: 8px;

  /* Cards - Landscape - Normal */
  --g--card-landscape-medium-aspect-ratio: calc(16 / 9);
  --g--card-landscape-medium-count: 1;
  --g--card-landscape-medium-width-unitless: 0; /* Computed with JS */
  --g--card-landscape-medium-height-unitless: 0; /* Computed with JS */
  --g--card-landscape-medium-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-landscape-medium-count) - 1))
      ) /
      var(--g--card-landscape-medium-count)
  );
  --g--card-landscape-medium-height: calc(
    var(--g--card-landscape-medium-width) / var(--g--card-landscape-medium-aspect-ratio)
  );

  /* Cards - Landscape - Big */
  --g--card-landscape-large-aspect-ratio: calc(16 / 9);
  --g--card-landscape-large-count: 1;
  --g--card-landscape-large-width-unitless: 0; /* Computed with JS */
  --g--card-landscape-large-height-unitless: 0; /* Computed with JS */
  --g--card-landscape-large-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-landscape-large-count) - 1))
      ) /
      var(--g--card-landscape-large-count)
  );
  --g--card-landscape-large-height: calc(
    var(--g--card-landscape-large-width) / var(--g--card-landscape-large-aspect-ratio)
  );

  /* Cards - Portrait - Normal */
  --g--card-portrait-medium-aspect-ratio: calc(21 / 25);
  --g--card-portrait-medium-count: 1;
  --g--card-portrait-medium-width-unitless: 0; /* Computed with JS */
  --g--card-portrait-medium-height-unitless: 0; /* Computed with JS */
  --g--card-portrait-medium-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-portrait-medium-count) - 1))
      ) /
      var(--g--card-portrait-medium-count)
  );
  --g--card-portrait-medium-height: calc(
    var(--g--card-portrait-medium-width) / var(--g--card-portrait-medium-aspect-ratio)
  );

  /* Cards - Portrait - Big */
  --g--card-portrait-large-aspect-ratio: calc(21 / 25);
  --g--card-portrait-large-count: 1;
  --g--card-portrait-large-width-unitless: 0; /* Computed with JS */
  --g--card-portrait-large-height-unitless: 0; /* Computed with JS */
  --g--card-portrait-large-width: calc(
    (
        var(--g--100vw) - (var(--g--margin-viewport) * 2) -
          (var(--g--card-gap) * (var(--g--card-portrait-large-count) - 1))
      ) /
      var(--g--card-portrait-large-count)
  );
  --g--card-portrait-large-height: calc(
    var(--g--card-portrait-large-width) / var(--g--card-portrait-large-aspect-ratio)
  );

  /* Cards - Sizes - Normal */
}

@media (width >= 620px) {
    :root {
      --g--card-landscape-medium-count: 2;
      --g--card-portrait-medium-count: 2;
    }
  }

@media (width >= 864px) {
    :root {
      --g--card-landscape-medium-count: 3;
      --g--card-portrait-medium-count: 3;
    }
  }

@media (width >= 1108px) {
    :root {
      --g--card-landscape-medium-count: 4;
      --g--card-portrait-medium-count: 4;
    }
  }

@media (width >= 1352px) {
    :root {
      --g--card-landscape-medium-count: 5;
      --g--card-portrait-medium-count: 5;
    }
  }

@media (width >= 1596px) {
    :root {
      --g--card-landscape-medium-count: 6;
      --g--card-portrait-medium-count: 6;
    }
  }

@media (width >= 1840px) {
    :root {
      --g--card-landscape-medium-count: 7;
      --g--card-portrait-medium-count: 7;
    }
  }

/* The 8 and 9 card setups are aimed for ultrawide screens and beyond. */

@media (width >= 2084px) and (aspect-ratio >= 2) {
    :root {
      --g--card-landscape-medium-count: 8;
      --g--card-portrait-medium-count: 8;
    }
  }

@media (width >= 2328px) and (aspect-ratio >= 3) {
    :root {
      --g--card-landscape-medium-count: 9;
      --g--card-portrait-medium-count: 9;
    }
  }

/* Cards - Landscape - Big - Sizes */

@media (width >= 620px) {
    :root {
      --g--card-landscape-large-count: 2;
      --g--card-portrait-large-count: 2;
    }
  }

@media (width >= 864px) {
    :root {
      --g--card-landscape-large-count: 3;
      --g--card-portrait-large-count: 3;
    }
  }

@media (width >= 1108px) {
    :root {
      --g--card-landscape-large-count: 3;
      --g--card-portrait-large-count: 3;
    }
  }

@media (width >= 1352px) {
    :root {
      --g--card-landscape-large-count: 4;
      --g--card-portrait-large-count: 4;
    }
  }

@media (width >= 1596px) {
    :root {
      --g--card-landscape-large-count: 5;
      --g--card-portrait-large-count: 5;
    }
  }

@media (width >= 1840px) {
    :root {
      --g--card-landscape-large-count: 6;
      --g--card-portrait-large-count: 6;
    }
  }

/* The 7 and 8 card setups are aimed for ultrawide screens and beyond. */

@media (width >= 2084px) and (aspect-ratio >= 2) {
    :root {
      --g--card-landscape-large-count: 7;
      --g--card-portrait-large-count: 7;
    }
  }

@media (width >= 2328px) and (aspect-ratio >= 3) {
    :root {
      --g--card-landscape-large-count: 8;
      --g--card-portrait-large-count: 8;
    }
  }

/* Style overrides for all elements */

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Root element styles */

html {
  user-select: none;

  overflow: hidden;
  overscroll-behavior: none;
  display: none;

  height: 100%;

  font-size: 62.5%;
  color: var(--g--color-white);

  background-color: var(--g--color-jet-black);
}

html.isRay {
    display: block;
  }

body {
  position: relative;

  overflow: hidden;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;
  margin: 0;

  font-family: var(--g--font);
  font-size: 18px;
  line-height: 1.5;
}

input,
textarea {
  font-family: var(--g--font);
  background-color: transparent;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  scroll-behavior: smooth;

  position: relative;

  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: none;

  width: 100%;
  height: 100%;
}
#__next::-webkit-scrollbar {
    width: 14px;
  }
#__next::-webkit-scrollbar-track {
    background: var(--g--color-black);
    box-shadow: inset 1px 0 4px var(--g--glass-dark-100);
  }
#__next::-webkit-scrollbar-thumb {
    background-color: var(--g--glass-light-100);
    background-clip: content-box;
    border: 5px solid transparent;
    border-radius: 100px;
  }
#__next::-webkit-scrollbar-thumb:hover {
    background-color: var(--g--glass-light-900);
  }

.footer_root__Zko6w {
  --focus-scroll-padding: 0px;
  --footer--content-height: 120px;
  --footer--logo-stamp-height: calc(var(--g--100vw) / 6);
  --footer--height: calc(var(--footer--content-height) + var(--footer--logo-stamp-height));

  content-visibility: auto;
  contain-intrinsic-size: 100% var(--footer--height);

  position: relative;

  contain: strict;

  width: 100%;
  height: var(--footer--height);
  padding: 0 var(--g--margin-viewport);

  font-size: 16px;
  line-height: 1.5;
  color: var(--g--color-grey);

  background: linear-gradient(86deg, #140f20 0.84%, #010e19 99.69%);
}

.footer_content__eaqNO {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: var(--footer--content-height);
}

@media (width <= 799px) {
    .footer_content__eaqNO {
      --footer--content-height: 140px;

      flex-flow: column nowrap;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }
  }

.footer_logo__z5B3Q {
  width: auto;
  height: 20px;
  margin-right: auto;
  color: var(--g--color-ray-orange);
}

@media (width <= 799px) {
    .footer_logo__z5B3Q {
      display: none;
    }
  }

.footer_links__jFci_ {
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--g--margin-md);
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 0;

  list-style: none;
}

@media (width <= 799px) {
    .footer_links__jFci_ {
      justify-content: center;
    }
  }

.footer_linkContainer__k290W {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_link__TtCV5 {
  display: block;

  padding: 0 4px;

  color: inherit;
  text-decoration: none;

  transition: color var(--g--button-anim-duration) var(--g--button-anim-easing);
}

html[data-nav-device='mouse'] .footer_link__TtCV5:hover {
    color: var(--g--color-white);
    text-decoration: underline;
  }

html[data-nav-device='keyboard'] .footer_link__TtCV5:not(:disabled):focus {
    border-radius: 4px;
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }

.footer_copyright__69W_S {
  margin: 0 0 0 var(--g--margin-md);
  color: var(--g--color-medium-grey);
}

@media (width <= 799px) {
    .footer_copyright__69W_S {
      width: 100%;
      margin: var(--g--margin-sm) 0 0 0;
      text-align: center;
    }
  }

.footer_logoStampContainer__8mO__ {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: var(--footer--logo-stamp-height);
}

.footer_logoStamp__ueshB {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;

  color: var(--g--color-white);

  opacity: 0.5;
  mix-blend-mode: overlay;
}

.base-button_root__Vm_ed {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 34px;
  margin: 0;
  padding: 0 24px;

  font-family: var(--g--font);
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  color: var(--g--color-white);
  text-decoration: none;
  text-rendering: geometricprecision;

  background: var(--g--color-jet-black);
  border: 0;
  border-radius: var(--g--button-border-radius);
  outline: 0;
}
.base-button_root__Vm_ed:has(svg) {
    width: 46px;
    padding: 0;
  }
.base-button_root__Vm_ed:is(:disabled,[aria-disabled='true']) {
    cursor: default;
  }
html[data-nav-device='keyboard'] .base-button_root__Vm_ed:focus {
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }
.base-button_root__Vm_ed svg {
    width: 22px;
    height: 22px;
  }

.button_root__gJwbc {
  box-shadow: var(--g--shadow-sm);
}

.button_root__gJwbc > * {
    pointer-events: none;
  }

.button_bgOverlay__pjo8F {
  position: absolute;
  inset: 0;

  display: block;

  opacity: 0;
  border-radius: inherit;

  transition: opacity var(--g--button-anim-duration) var(--g--button-anim-easing);
}

.button_content__Cwt6R {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button_highlight__mwj19 {
  position: absolute;
  inset: 0;

  display: block;

  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-sm);
}

.button_themePrimary__UTsyV {
  background: var(--g--color-ray-orange);
}

.button_themePrimary__UTsyV > .button_hover__1WnUE {
    background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%),
      var(--g--color-ray-orange);
    background-blend-mode: soft-light, normal;
  }

.button_themePrimary__UTsyV > .button_pressed__pY3Xd {
    background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 100%),
      var(--g--color-ray-orange);
    background-blend-mode: soft-light, normal;
  }

.button_themeSecondaryLight__vIMyw {
  background: var(--g--glass-light-100);
  backdrop-filter: blur(20px);
}

.button_themeSecondaryLight__vIMyw > .button_hover__1WnUE {
    background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%);
    mix-blend-mode: soft-light;
  }

.button_themeSecondaryLight__vIMyw > .button_pressed__pY3Xd {
    background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 100%);
    mix-blend-mode: soft-light;
  }

.button_themeSecondaryDark__aeoDd {
  background: var(--g--glass-dark-100);
  backdrop-filter: blur(20px);
}

.button_themeSecondaryDark__aeoDd > .button_hover__1WnUE {
    background: rgb(255 255 255 / 3%);
  }

.button_themeSecondaryDark__aeoDd > .button_pressed__pY3Xd {
    background: rgb(255 255 255 / 6%);
  }

.button_themeSecondaryDark__aeoDd > .button_highlight__mwj19 {
    box-shadow:
      var(--g--inset-highlight-sm),
      inset 0 0 0 1px var(--g--glass-light-100);
  }

.button_themeDiscord__vmfoE {
  background: #586aea;
}

.button_themeDiscord__vmfoE > .button_hover__1WnUE {
    background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%), #586aea;
    background-blend-mode: soft-light, normal;
  }

.button_themeDiscord__vmfoE > .button_pressed__pY3Xd {
    background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 100%), #586aea;
    background-blend-mode: soft-light, normal;
  }

html[data-nav-device='mouse'] .button_root__gJwbc:hover > .button_hover__1WnUE,
html[data-nav-device='mouse'] .button_root__gJwbc:active > .button_pressed__pY3Xd {
  opacity: 1;
}

.player-questionnaire_root__kyW4n {
  position: fixed;
  z-index: 100;
  top: 16px;
  right: 0;

  display: flex;

  width: 100%;
  max-width: 616px;
  padding: 0 calc(16px + 14px) 0 16px;
}

.player-questionnaire_content__DyrlK {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 616px;
  padding: 12px 20px;

  background: #0009;
  border: 1px solid #fffb;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 #00000059;
}

.player-questionnaire_isVisible__w3dc8 .player-questionnaire_content__DyrlK {
    animation: player-questionnaire_slideIn__ZMH1U 500ms cubic-bezier(0.575, 0.01, 0.01, 1) forwards;
  }

.player-questionnaire_isHidden__MqRpX .player-questionnaire_content__DyrlK {
    animation: player-questionnaire_slideOut__9C2Mo 500ms cubic-bezier(0.575, 0.01, 0.01, 1) forwards;
  }

@media (width >= 600px) {
    .player-questionnaire_content__DyrlK {
      flex-wrap: nowrap;
    }
  }

.player-questionnaire_leftColumn___9n1X {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 16px;
  align-items: flex-start;
}

@media (width >= 600px) {
    .player-questionnaire_leftColumn___9n1X {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

.player-questionnaire_description__1OSyD {
  margin: 0;
  font-size: 14px;
}

@media (width >= 600px) {
    .player-questionnaire_description__1OSyD {
      flex-grow: 2;
      min-width: auto;
      text-wrap: nowrap;
    }
  }

.player-questionnaire_closeButton__TnoET:has(svg) {
    width: 18px;
    height: 18px;
    background: none;
  }

:scope .player-questionnaire_closeIcon__Jhjk8 {
  cursor: pointer;
  color: #fff;
  opacity: 0.4;
  transition: opacity 0.15 ease-out;
}

html[data-nav-device='mouse'] .player-questionnaire_closeButton__TnoET:hover :is(:scope .player-questionnaire_closeIcon__Jhjk8),html[data-nav-device='keyboard'] .player-questionnaire_closeButton__TnoET:focus :is(:scope .player-questionnaire_closeIcon__Jhjk8) {
    opacity: 1;
  }

@keyframes player-questionnaire_slideIn__ZMH1U {
  from {
    transform: translateY(40px);
    opacity: 0;
    backdrop-filter: blur(0);
  }

  to {
    transform: translateY(0);
    opacity: 1;
    backdrop-filter: blur(20px);
  }
}

@keyframes player-questionnaire_slideOut__9C2Mo {
  from {
    transform: translateY(0);
    opacity: 1;
    backdrop-filter: blur(20px);
  }

  to {
    transform: translateY(40px);
    opacity: 0;
    backdrop-filter: blur(0);
  }
}

.modal_root__BBR6J {
  pointer-events: none;
  contain-intrinsic-size: 100% 100%;

  position: fixed;
  z-index: 100000;
  inset: 0;

  contain: strict;
  overflow: hidden;

  width: 100%;
  height: 100%;
}

.modal_root__BBR6J.modal_isOpen__F_vIm {
    pointer-events: auto;
  }

.modal_overlay__V3TZT {
  position: absolute;
  inset: 0;

  opacity: 0;
  background: rgb(0 0 0 / 80%);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);
}

.modal_root__BBR6J.modal_isOpen__F_vIm .modal_overlay__V3TZT {
    opacity: 1;
  }

.modal_content__WqMd7 {
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.loading-spinner_root__zbiRB {
  --loading-spinner--bg-color: var(--g--glass-light-100);
  --loading-spinner--color: var(--g--color-ray-orange);
  --loading-spinner--show-duration: 0.5s;
  --loading-spinner--hide-duration: 0.2s;
  --loading-spinner--load-duration: 1s;
  --loading-spinner--show-easing: cubic-bezier(0.125, 0.935, 0.115, 0.955);
  --loading-spinner--hide-easing: ease-in-out;
  --loading-spinner--load-easing: ease-in-out;

  position: absolute;
  inset: 0;
  transform: scaleX(0);

  overflow: hidden;

  transition: transform var(--loading-spinner--hide-duration) var(--loading-spinner--hide-easing);
}

.loading-spinner_root__zbiRB::before {
    content: '';

    position: absolute;
    top: calc(50% - 1px);
    left: 0;

    width: 100%;
    height: 2px;

    border-top: 2px solid var(--loading-spinner--bg-color);
  }

.loading-spinner_root__zbiRB::after {
    content: '';

    position: absolute;
    top: calc(50% - 2px);
    left: 0;

    width: 100%;
    height: 4px;

    opacity: 0;
    border-top: 4px solid var(--loading-spinner--color);

    transition: opacity 0s linear var(--loading-spinner--hide-duration);
  }

.loading-spinner_root__zbiRB.loading-spinner_isActive__WdJJP {
    transform: scaleX(1);
    transition-timing-function: var(--loading-spinner--show-easing);
    transition-duration: var(--loading-spinner--show-duration);
  }

.loading-spinner_root__zbiRB.loading-spinner_isActive__WdJJP::after {
    opacity: 1;
    transition-delay: var(--loading-spinner--show-duration);
    animation: var(--loading-spinner--load-duration) var(--loading-spinner--load-easing)
      var(--loading-spinner--show-duration) loading-spinner_loadingSpinnerAnimation__bUhQy infinite;
  }

@keyframes loading-spinner_loadingSpinnerAnimation__bUhQy {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.markdown_root__qIcXk {
  --markdown--margin: var(--g--card-gap);
  --markdown--list-counter-width: calc(var(--markdown--margin) * 1.5);

  user-select: text;

  font-size: 15px;
  line-height: 1.5;
  color: var(--g--color-light-grey);
  word-break: break-word;
}
.markdown_root__qIcXk p {
    margin: 0 0 var(--markdown--margin) 0;
  }
.markdown_root__qIcXk hr {
    margin: calc(var(--markdown--margin) * 2) 0;
    border: 0;
    border-top: 1px solid var(--g--glass-light-100);
  }
.markdown_root__qIcXk blockquote {
    margin: 0 0 var(--markdown--margin) 0;
    padding: 0 0 0 var(--markdown--margin);
    border-left: 1px solid var(--g--glass-light-100);
  }
.markdown_root__qIcXk ul,.markdown_root__qIcXk ol {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    gap: calc(var(--markdown--margin) / 3) calc(var(--markdown--margin) / 2);

    margin: 0 0 var(--markdown--margin) 0;
    padding: 0;

    list-style-type: none;
  }
:is(.markdown_root__qIcXk ul,.markdown_root__qIcXk ol):is(ol) {
      counter-reset: item;
    }
li :is(.markdown_root__qIcXk ul,.markdown_root__qIcXk ol) {
      margin: 0;
    }
.markdown_liContent__2vtMV > :is(.markdown_root__qIcXk ul,.markdown_root__qIcXk ol) {
      margin-top: calc(var(--markdown--margin) / 3);
    }
.markdown_root__qIcXk li {
    display: contents;
  }
:is(.markdown_root__qIcXk li) .markdown_liMarker__OHC3q {
      display: flex;
      align-items: flex-start;

      font-family: var(--g--font-mono);
      color: var(--g--color-ray-orange);
      white-space: nowrap;
    }
:is(ol > li > :is(:is(.markdown_root__qIcXk li) .markdown_liMarker__OHC3q))::before {
          content: counters(item, '.') ' ';
          counter-increment: item;
        }
:is(ol > li > :is(:is(.markdown_root__qIcXk li) .markdown_liMarker__OHC3q)) > svg {
          display: none;
        }
ul > li > :is(:is(.markdown_root__qIcXk li) .markdown_liMarker__OHC3q) > svg {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-top: calc((15px * 1.5) / 2 - 4px);
      }
:is(.markdown_root__qIcXk li) .markdown_liContent__2vtMV {
      display: block;
    }
.markdown_root__qIcXk h1,.markdown_root__qIcXk h2,.markdown_root__qIcXk h3,.markdown_root__qIcXk h4,.markdown_root__qIcXk h5,.markdown_root__qIcXk h6 {
    margin: calc(var(--markdown--margin) * 1.5) 0 var(--markdown--margin) 0;

    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--g--color-white);
  }
.markdown_root__qIcXk a {
    color: var(--g--color-ray-orange);
    text-decoration: none;
  }
html[data-nav-device='mouse'] :is(.markdown_root__qIcXk a):hover,html[data-nav-device='keyboard'] :is(.markdown_root__qIcXk a):focus {
      text-decoration: underline;
    }
html[data-nav-device='keyboard'] :is(.markdown_root__qIcXk a):not(:disabled):focus {
      border-radius: 4px;
      outline: 1px solid var(--g--color-white);
      outline-offset: 2px;
    }
.markdown_root__qIcXk > :first-child {
    margin-top: 0;
  }
.markdown_root__qIcXk > :last-child {
    margin-bottom: 0;
  }

.shimmer_root__a71Yt {
  /* These are set with JS */
  --shimmer--strength: 0%;
  --shimmer--duration: 0ms;
  --shimmer--iteration-count: 1;

  position: absolute;
  inset: 0;

  overflow: hidden;

  opacity: 0;

  transition: opacity 200ms;
}

.shimmer_root__a71Yt.shimmer_isActive__DJSj6 {
    opacity: 1;
  }

.shimmer_wrapper__QTJW2 {
  position: absolute;
  inset: 0;
  transform: translate(-80%);

  width: 200%;

  opacity: 0;
}

.shimmer_isActive__DJSj6 .shimmer_wrapper__QTJW2 {
    animation-name: shimmer_glare__sKJaT;
    animation-duration: var(--shimmer--duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: var(--shimmer--iteration-count);
  }

.shimmer_ripple__0_vif {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    -60deg,
    rgb(255 255 255 / 0%) 40%,
    rgb(255 255 255 / var(--shimmer--strength)) 45%,
    rgb(255 255 255 / var(--shimmer--strength)) 55%,
    rgb(255 255 255 / 0%) 60%
  );
}

@keyframes shimmer_glare__sKJaT {
  0% {
    transform: translate(-80%);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate(50%);
    opacity: 0;
  }
}

.video-player_root__ZDI12 {
  position: relative;

  overflow: hidden;
  display: flex;

  width: 100%;

  border-radius: var(--g--card-border-radius);
}

.video-player_poster__uanLw {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  opacity: 1;
  object-fit: cover;
  object-position: center center;

  transition: opacity 0.3s ease-out;
}

.video-player_isPlaying__qtQIO .video-player_poster__uanLw {
    opacity: 0;
  }

.video-player_videoContainer__tK0nZ {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.video-player_video__nCR0B {
  pointer-events: none;

  width: 100%;
  height: 100%;

  opacity: 0;
  object-fit: cover;
  object-position: center center;

  transition: opacity 0.3s ease-out;
}

.video-player_isPlaying__qtQIO .video-player_video__nCR0B {
    opacity: 1;
  }

.video-player_toggleVideo__NyHVA {
  position: absolute;
  bottom: var(--g--margin-sm);
  left: var(--g--margin-sm);
  transform: translateX(0) scale(1);

  opacity: 1;

  transition:
    opacity 0.3s,
    transform 0.3s ease-in;
}

.video-player_isPlaying__qtQIO .video-player_toggleVideo__NyHVA {
    transform: translateX(-32px) scale(0.75);
    opacity: 0;
  }

html[data-nav-device='mouse'] .video-player_root__ZDI12:hover :is(.video-player_isPlaying__qtQIO .video-player_toggleVideo__NyHVA),html[data-nav-device='keyboard'] .video-player_root__ZDI12:focus-within :is(.video-player_isPlaying__qtQIO .video-player_toggleVideo__NyHVA) {
      transform: translateX(0) scale(1);
      opacity: 1;
      transition:
        opacity 0.3s var(--g--anim-bounce-easing),
        transform 0.3s var(--g--anim-bounce-easing);
    }

.video-player_muteVideo__0Qz84 {
  pointer-events: none;

  position: absolute;
  right: var(--g--margin-sm);
  bottom: var(--g--margin-sm);
  transform: translateX(32px) scale(0.75);

  opacity: 0;

  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-in;
}

.video-player_isPlaying__qtQIO .video-player_muteVideo__0Qz84 {
    pointer-events: auto;
  }

html[data-nav-device='mouse'] .video-player_root__ZDI12:hover :is(.video-player_isPlaying__qtQIO .video-player_muteVideo__0Qz84),html[data-nav-device='keyboard'] .video-player_root__ZDI12:focus-within :is(.video-player_isPlaying__qtQIO .video-player_muteVideo__0Qz84) {
      transform: translateX(0) scale(1);
      opacity: 1;
      transition:
        opacity 0.3s var(--g--anim-bounce-easing),
        transform 0.3s var(--g--anim-bounce-easing);
    }

.video-player_highlight__ANgKQ {
  pointer-events: none;

  position: absolute;
  inset: 0;

  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-md);
}

.tag_root__hkb2I {
  display: inline-block;
  align-items: center;

  height: 25px;
  padding: 0 8px;

  font-size: 11px;
  font-weight: 500;
  line-height: 22px;
  color: var(--g--glass-light-500);
  text-transform: uppercase;
  letter-spacing: 0.4px;
  white-space: nowrap;

  border: 1.5px solid;
  border-radius: var(--g--border-radius-sm);
}

a.tag_root__hkb2I {
  text-decoration: none;
  outline: 0;
  transition:
    color var(--g--button-anim-duration) var(--g--button-anim-easing),
    border-color var(--g--button-anim-duration) var(--g--button-anim-easing);
}

html[data-nav-device='mouse'] a.tag_root__hkb2I:hover,html[data-nav-device='keyboard'] a.tag_root__hkb2I:focus {
    color: var(--g--color-white);
  }

html[data-nav-device='keyboard'] a.tag_root__hkb2I:focus {
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }

.detail-modal_root__9_QpY {
  --app-modal--margin: 32px;
  --app-modal--padding: 8px;
  --app-modal--content-margin: 32px;
  --app-modal--content-padding: 24px;
  --app-modal--scrollbar-width: 0px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: var(--app-modal--margin);
}

@media (width <= 767px) {
    .detail-modal_root__9_QpY {
      --app-modal--margin: 24px;
      --app-modal--content-margin: 24px;
      --app-modal--content-padding: 16px;
    }
  }

.detail-modal_loader__KaWpg {
  position: relative;
  width: calc(100% - var(--app-modal--padding) - var(--app-modal--padding));
  max-width: calc(828px - var(--app-modal--padding) - var(--app-modal--padding));
  height: 4px;
}

.detail-modal_closeButton__KOqQg {
  position: relative;
}

.detail-modal_panel__NW1FR {
  pointer-events: auto;

  position: relative;
  transform: translateY(50px);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 828px;
  max-height: 100%;

  opacity: 0;
  background: radial-gradient(
      42.9% 50.02% at 50% 50.02%,
      rgb(158 38 81 / 40%) 0%,
      rgb(158 38 81 / 0%) 100%
    ),
    var(--g--color-black);
  border-radius: var(--g--border-radius-md);
  box-shadow:
    var(--g--highlight-md),
    0 0 0 1.5px var(--g--glass-light-100),
    var(--g--shadow-xl);

  transition:
    transform var(--g--anim-duration) ease-in-out,
    opacity var(--g--anim-duration) ease-in-out;
}

.detail-modal_panel__NW1FR.detail-modal_isPanelOpen__HsAxz {
    transform: translateY(0);
    opacity: 1;
    transition:
      transform calc(var(--g--anim-duration) * 2) cubic-bezier(0.125, 0.935, 0.115, 0.955),
      opacity var(--g--anim-duration) linear;
  }

.detail-modal_errorPanel___2dbo {
  flex-flow: row nowrap;
  gap: var(--app-modal--content-margin);
  align-items: flex-start;
  justify-content: space-between;

  max-width: 600px;
  padding: var(--app-modal--content-padding);
}

.detail-modal_errorPanel___2dbo p {
    position: relative;

    margin: 0;

    font-size: 15px;
    line-height: 1.5;
    word-break: break-word;
  }

.detail-modal_errorPanel___2dbo .detail-modal_closeButton__KOqQg {
    flex-shrink: 0;
  }

.detail-modal_contentPanel__7tD6r .detail-modal_closeButton__KOqQg {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: calc(20px + var(--app-modal--scrollbar-width));
  }

.detail-modal_contentPanel__7tD6r .detail-modal_content__cGtwZ {
    scroll-behavior: smooth;

    position: relative;

    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    flex-grow: 1;
    flex-shrink: 1;

    width: 100%;
    padding: var(--app-modal--padding);
  }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_content__cGtwZ)::-webkit-scrollbar {
      width: 11px;
    }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_content__cGtwZ)::-webkit-scrollbar-track {
      background-color: transparent;
    }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_content__cGtwZ)::-webkit-scrollbar-thumb {
      background-color: var(--g--glass-light-100);
      background-clip: content-box;
      border: 0 solid transparent;
      border-top-width: var(--g--border-radius-md);
      border-right-width: 8px;
      border-bottom-width: var(--g--border-radius-md);
    }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_content__cGtwZ)::-webkit-scrollbar-thumb:hover {
      background-color: var(--g--glass-light-900);
    }

.detail-modal_contentPanel__7tD6r .detail-modal_videoPlayerContainer__mxcG9 {
    --focus-scroll-padding: 0px;

    position: relative;
    width: 100%;
  }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_videoPlayerContainer__mxcG9) .detail-modal_videoPlayer__bc4Bf {
      aspect-ratio: 16 / 9;
      background-color: var(--g--glass-light-100);
      box-shadow: var(--g--shadow-md);
    }

.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS {
    display: flex;
    flex-flow: row-reverse nowrap;
    gap: var(--app-modal--content-margin);

    margin: var(--app-modal--content-padding);
    margin-top: var(--app-modal--content-margin);
  }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw {
      --focus-scroll-padding: 32px 0px;

      position: relative;

      flex-grow: 1;
      flex-shrink: 1;

      width: 100%;
      min-width: 0;

      outline: 0;
    }

:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_title__VOJPk {
        margin: 0;
        font-size: 40px;
        font-weight: 300;
        line-height: 1.15;
      }

:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_description__lFh0C {
        margin-top: var(--app-modal--content-margin);
      }

:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq {
        display: none;

        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        text-shadow: 0 1px 2px var(--g--glass-dark-100);
      }

:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq):has(dt) {
          display: inline-grid;
          grid-template-columns: repeat(2, auto);
          margin: var(--app-modal--content-margin) 0 0 0;
        }

:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq) > dt {
          display: block;
          color: var(--g--glass-light-500);
          white-space: nowrap;
        }

:is(:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq) > dt):not(:nth-child(1)) {
            margin-top: var(--g--margin-sm);
          }

:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq) > dd {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;

          margin: 0 0 0 var(--g--margin-sm);

          color: var(--g--color-light-grey);
          word-break: break-word;
        }

:is(:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_metadata__ejddq) > dd):not(:nth-child(2)) {
            margin-top: var(--g--margin-sm);
          }

:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_categories__psyx9 {
        display: flex;
        flex-flow: row wrap;
        gap: 4px;
        justify-content: flex-start;

        width: 100%;
        margin-top: var(--app-modal--content-margin);
      }

:is(:is(:is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) .detail-modal_infoLeft__o0Efw) .detail-modal_categories__psyx9) > * {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }

@media (width <= 767px) {
      :is(.detail-modal_contentPanel__7tD6r .detail-modal_info__VvbGS) {
        flex-flow: column nowrap;
      }
    }

.detail-modal_contentPanel__7tD6r .detail-modal_infoRight__XLFwI {
    flex-grow: 0;
    flex-shrink: 0;
    width: clamp(200px, 30%, 300px);
  }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_infoRight__XLFwI) .detail-modal_infoRightSticky__GBZas {
      position: sticky;
      top: var(--app-modal--content-padding);
    }

:is(.detail-modal_contentPanel__7tD6r .detail-modal_infoRight__XLFwI) .detail-modal_launchAction__oYDJm {
      --focus-scroll-padding: 32px 0px;

      width: 100%;
    }

@media (width <= 767px) {
      :is(.detail-modal_contentPanel__7tD6r .detail-modal_infoRight__XLFwI) {
        width: 100%;
        min-width: 0;
      }
    }

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/1ed5590bc390621f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/98e01a550dd67bfd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/6fa1afdfb81c9ae2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/114d0b1e2fb44d56-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/50918917bb137e4d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/8c3afd4c64537d1c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/a3512529a0e86962-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__suisseIntl_4f0dd8';
src: url(/_next/static/media/b1c153452a79a6bb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}@font-face {font-family: '__suisseIntl_Fallback_4f0dd8';src: local("Arial");ascent-override: 95.68%;descent-override: 30.18%;line-gap-override: 0.00%;size-adjust: 103.05%
}.__className_4f0dd8 {font-family: '__suisseIntl_4f0dd8', '__suisseIntl_Fallback_4f0dd8'
}

@font-face {
font-family: '__suisseIntlCondensed_c778cd';
src: url(/_next/static/media/7007462e4b66b5e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlCondensed_c778cd';
src: url(/_next/static/media/30043ba7d950cac8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__suisseIntlCondensed_Fallback_c778cd';src: local("Arial");ascent-override: 109.42%;descent-override: 34.51%;line-gap-override: 0.00%;size-adjust: 90.11%
}.__className_c778cd {font-family: '__suisseIntlCondensed_c778cd', '__suisseIntlCondensed_Fallback_c778cd'
}

@font-face {
font-family: '__suisseIntlMono_a44523';
src: url(/_next/static/media/4c017ab9f28baa7b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__suisseIntlMono_Fallback_a44523';src: local("Arial");ascent-override: 69.65%;descent-override: 21.97%;line-gap-override: 0.00%;size-adjust: 141.57%
}.__className_a44523 {font-family: '__suisseIntlMono_a44523', '__suisseIntlMono_Fallback_a44523';font-weight: 400;font-style: normal
}

