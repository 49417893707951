.root {
  --focus-scroll-padding: 0px;
  --footer--content-height: 120px;
  --footer--logo-stamp-height: calc(var(--g--100vw) / 6);
  --footer--height: calc(var(--footer--content-height) + var(--footer--logo-stamp-height));

  content-visibility: auto;
  contain-intrinsic-size: 100% var(--footer--height);

  position: relative;

  contain: strict;

  width: 100%;
  height: var(--footer--height);
  padding: 0 var(--g--margin-viewport);

  font-size: 16px;
  line-height: 1.5;
  color: var(--g--color-grey);

  background: linear-gradient(86deg, #140f20 0.84%, #010e19 99.69%);
}

.content {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: var(--footer--content-height);

  @media (width <= 799px) {
    & {
      --footer--content-height: 140px;

      flex-flow: column nowrap;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }
  }
}

.logo {
  width: auto;
  height: 20px;
  margin-right: auto;
  color: var(--g--color-ray-orange);

  @media (width <= 799px) {
    & {
      display: none;
    }
  }
}

.links {
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--g--margin-md);
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 0;

  list-style: none;

  @media (width <= 799px) {
    & {
      justify-content: center;
    }
  }
}

.linkContainer {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link {
  display: block;

  padding: 0 4px;

  color: inherit;
  text-decoration: none;

  transition: color var(--g--button-anim-duration) var(--g--button-anim-easing);

  html[data-nav-device='mouse'] &:hover {
    color: var(--g--color-white);
    text-decoration: underline;
  }

  html[data-nav-device='keyboard'] &:not(:disabled):focus {
    border-radius: 4px;
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }
}

.copyright {
  margin: 0 0 0 var(--g--margin-md);
  color: var(--g--color-medium-grey);

  @media (width <= 799px) {
    & {
      width: 100%;
      margin: var(--g--margin-sm) 0 0 0;
      text-align: center;
    }
  }
}

.logoStampContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: var(--footer--logo-stamp-height);
}

.logoStamp {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: auto;

  color: var(--g--color-white);

  opacity: 0.5;
  mix-blend-mode: overlay;
}
