.root {
  display: inline-block;
  align-items: center;

  height: 25px;
  padding: 0 8px;

  font-size: 11px;
  font-weight: 500;
  line-height: 22px;
  color: var(--g--glass-light-500);
  text-transform: uppercase;
  letter-spacing: 0.4px;
  white-space: nowrap;

  border: 1.5px solid;
  border-radius: var(--g--border-radius-sm);
}

a.root {
  text-decoration: none;
  outline: 0;
  transition:
    color var(--g--button-anim-duration) var(--g--button-anim-easing),
    border-color var(--g--button-anim-duration) var(--g--button-anim-easing);

  html[data-nav-device='mouse'] &:hover,
  html[data-nav-device='keyboard'] &:focus {
    color: var(--g--color-white);
  }

  html[data-nav-device='keyboard'] &:focus {
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }
}
